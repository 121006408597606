<div class="container py-3">
  <h1>About Episode Ninja</h1>
  <p>
    The goal of this site is to provide a list of the "best" episodes of any TV show. I aggregate user-generated episode ratings to figure this out. Data from the following projects allows me to accomplish that:
  </p>
  <ul>
    <li><a href="http://thetvdb.com/" rel="noopener" target="_blank">TheTVDB</a></li>
    <li><a href="https://trakt.tv/" rel="noopener" target="_blank">trakt.tv</a></li>
    <li><a href="https://www.themoviedb.org/" rel="noopener" target="_blank">The Movie DB (TMDb)</a></li>
    <li><a href="https://tastedive.com/" rel="noopener" target="_blank">TasteDive</a></li>
  </ul>
  <h2>Where's my favorite show?</h2>
  <p>
    If you think a show is missing, feel free to send me an email at <a href="mailto:contact@episode.ninja">contact@episode.ninja</a> and I'll get it added as soon as possible.
  </p>
</div>
