<div class="container">
  <h1>Sign In</h1>
  <form (ngSubmit)="onSubmitLogin(email, password)">
    <div class="form-group">
      <label>Email:</label>
      <input [(ngModel)]="email" name="email" type="email" class="form-control" required>
    </div>
    <div class="form-group">
      <label>Password:</label>
      <input [(ngModel)]="password" name="password" type="password" class="form-control" required>
    </div>
    <button type="submit" class="btn btn-block btn-primary">Sign In</button>
  </form>
</div>
