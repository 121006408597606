export const environment = {
  production: true,
  apiUrl: 'https://api.episode.ninja',
  amplify: {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_EBlbN75ib',
      userPoolWebClientId: '28bcj4n54c83vu1hda6ciibs2s'
    }
  }
};
