<div class="modal-dialog">
  <div class="modal-content">
     <div class="modal-header">
        <button type="button" class="close" (click)="close()" class="float-right">&times;</button>
     </div>
     <div class="modal-body">
        <p>Join the Episode Ninja community to vote for your favorite episodes!</p>
     </div>
     <div class="modal-footer">
        <button type="button" class="btn" (click)="confirm('/login')">
          Log In
        </button>
        <button type="button" class="btn btn-primary" (click)="confirm('/signup')">
          Sign Up
        </button>
     </div>
  </div>
</div>
