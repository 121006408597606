<div class="container">
  <h1 class="pt-1">Search Results</h1>

  <div class="row pb-3">
    <app-show-card *ngFor="let show of shows" [show]="show" class="col-sm-6 col-lg-4"></app-show-card>
    <div class="col" *ngIf="shows?.length === 0">
      Nothing found!
    </div>
  </div>
</div>
