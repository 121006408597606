<div class="container">
  <h1 class="mb-3">Sign Up</h1>
  <div class="signup" *ngIf="!successfulSignup">
    <form [formGroup]="signupForm" (ngSubmit)="onSubmitSignup(signupForm.value)">
      <div class="form-group">
        <label>Email* </label>
        <input type="email" formControlName="email" class="form-control" required>
      </div>
      <div class="form-group">
        <label>Password* </label>
        <input type="password" formControlName="password" class="form-control" minlength="8" required>
        <p class="form-text text-muted">
          Your password must be at least 8 characters long
        </p>
      </div>

      <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
        {{errorMessage}}
      </div>

      <button type="submit" class="btn btn-primary btn-block" [disabled]="!signupForm.valid || submitting">Sign Up</button>
    </form>
  </div>

  <div *ngIf="successfulSignup">
    <p>Success! You can now start casting votes on episodes.</p>

    <a class="btn btn-primary" href="/">Get Started</a>
  </div>
</div>
