<div class="site-wrap">
  <nav class="navbar" [class.bg-inverse]="!router.url.startsWith('/series')" *ngIf="!router.url.startsWith('/podcast')">
    <div class="container">
      <div class="row">
        <div class="col-2 hidden-sm-up">
          <button type="button" class="btn btn-link btn-search" (click)="toggleSearch()" *ngIf="router.url !== '/'">
            <img src="/assets/search.svg" alt="Search">
          </button>
        </div>
        <div class="col-8 d-flex">
          <a class="navbar-brand" href="/">
            <img src="/assets/logo.svg" width="30" height="30" class="float-left mr-1" alt="Episode Ninja">
            Episode Ninja<br>
            <small>the best episodes of any tv show</small>
          </a>
        </div>
        <div class="col-12" *ngIf="showSearch" [@enterAnimation] style="position: absolute; top: 36px;">
          <app-search
            style="min-width: 250px;"
            class="mb-1"
            (submitted)="toggleSearch()"
          ></app-search>
        </div>
        <div class="col-sm-4 hidden-sm-down">
          <app-search
            *ngIf="router.url !== '/'"
            style="min-width: 250px;"
            class="mb-1"
          ></app-search>
        </div>
      </div>
    </div>
  </nav>

  <main>
    <router-outlet></router-outlet>
  </main>

  <footer class="bg-inverse text-white pt-3 pb-2">
    <div class="container">
      <span class="float-sm-right">&copy; Copyright {{currentYear}} <a href="/" class="text-white">Episode Ninja</a></span>
      <ul class="list-inline">
        <li class="list-inline-item"><a href="/about" class="text-white">About</a></li>
        <li class="list-inline-item"><a href="/shows" class="text-white">Shows</a></li>
        <li class="list-inline-item">
          <a href="https://www.iubenda.com/privacy-policy/23600908/legal" target="_blank" class="text-white" rel="noopener">
            Privacy Policy
          </a>
        </li>
      </ul>
      <small>We are a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for us to earn fees by linking to Amazon.com and affiliated sites.</small>
    </div>
  </footer>
</div>

