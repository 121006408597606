<div class="bg-inverse">
  <h1 class="container text-white mb-3 py-3 podcast-title">
    the<br> <span>episode ninja</span><br> podcast
  </h1>
</div>

<div class="container">
  <div class="row">
    <!-- all episodes -->
    <div class="col content" *ngIf="podcast">
      <p>{{podcast?.description}}</p>
      <div class="card mb-3" *ngFor="let item of podcast?.items">
        <div class="card-block">
          <h2 class="card-title">
            <a [href]="'/podcast/' + item.slug">{{item.title}}</a>
          </h2>
          <h3 class="h6 card-subtitle text-muted mb-3">{{item.pubDate | date}}</h3>

          <div class="card-text" [innerHTML]="item.content"></div>

          <iframe [src]="getPlayer(item.player)" frameborder="0" height="100" width="100%" scrolling="no"></iframe>
        </div>
      </div>
    </div>

    <!-- single episodes -->
    <div class="col content" *ngIf="episode">
      <div class="card-block">
        <h2 class="card-title">{{episode.title}}</h2>
        <h3 class="h6 card-subtitle text-muted mb-3">{{episode.pubDate | date}}</h3>

        <div class="card-text" [innerHTML]="episode.content"></div>

        <iframe [src]="getPlayer(episode.player)" frameborder="0" height="100" width="100%" scrolling="no"></iframe>
      </div>
    </div>
  </div>
</div>
