<div  class="py-3 bg-inverse home-hero">
  <div class="container my-md-5" style="max-width: 660px">
    <h1 class="text-white text-center h3 mb-3">The Best Episodes of Your Favorite Shows</h1>
    <app-search></app-search>
    <p class="text-white text-center mt-1">
      Compiling millions of viewer ratings to rank the best episodes of any TV show
    </p>

    <div class="text-center">
      <a [href]="'https://facebook.com/sharer/sharer.php?u=https://episode.ninja'" target="_blank" rel="noopener" aria-label="Share on Facebook" class="sharing-button facebook col" title="Share on Facebook">
        <svg width="20" height="20" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="1.414"><path d="M15.117 0H.883C.395 0 0 .395 0 .883v14.234c0 .488.395.883.883.883h7.663V9.804H6.46V7.39h2.086V5.607c0-2.066 1.262-3.19 3.106-3.19.883 0 1.642.064 1.863.094v2.16h-1.28c-1 0-1.195.48-1.195 1.18v1.54h2.39l-.31 2.42h-2.08V16h4.077c.488 0 .883-.395.883-.883V.883C16 .395 15.605 0 15.117 0" fill-rule="nonzero"/></svg>
        <span>Share</span>
      </a>

      <a [href]="'https://twitter.com/intent/tweet/?url=https://episode.ninja'" target="_blank" rel="noopener" aria-label="Share on Twitter" class="sharing-button twitter col" title="Share on Twitter">
        <svg width="20" height="20" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="1.414"><path d="M16 3.038c-.59.26-1.22.437-1.885.517.677-.407 1.198-1.05 1.443-1.816-.634.37-1.337.64-2.085.79-.598-.64-1.45-1.04-2.396-1.04-1.812 0-3.282 1.47-3.282 3.28 0 .26.03.51.085.75-2.728-.13-5.147-1.44-6.766-3.42C.83 2.58.67 3.14.67 3.75c0 1.14.58 2.143 1.46 2.732-.538-.017-1.045-.165-1.487-.41v.04c0 1.59 1.13 2.918 2.633 3.22-.276.074-.566.114-.865.114-.21 0-.41-.02-.61-.058.42 1.304 1.63 2.253 3.07 2.28-1.12.88-2.54 1.404-4.07 1.404-.26 0-.52-.015-.78-.045 1.46.93 3.18 1.474 5.04 1.474 6.04 0 9.34-5 9.34-9.33 0-.14 0-.28-.01-.42.64-.46 1.2-1.04 1.64-1.7z" fill-rule="nonzero"/></svg>
        <span>Tweet</span>
      </a>
    </div>

  </div>
</div>

<div class="py-3">
  <div class="container">
    <app-popular-shows></app-popular-shows>
  </div>
</div>
